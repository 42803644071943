<template>
  <div>
    <div
      class="w-100 d-flex justify-center align-center flex-column pa-5"
      rounded="0"
    >
      <div>
        <v-avatar size="100">
          <v-img
            :src="
              $store.state.user.user.imageUrl
                ? 'https://api-mas3a.iips.edu.iq' +
                  '/' +
                  $store.state.user.user.imageUrl
                : this.$store.state.defaultPhoto
            "
            width="200"
            height="200"
          >
          </v-img>
        </v-avatar>
      </div>
      <div>
        <h3 class="">{{ $store.state.user.fullName }}</h3>
      </div>
    </div>

    <v-list>
      <v-list-item>
        <v-list-item-title
          ><strong>{{ $t("username") }}: </strong></v-list-item-title
        >
        <strong>{{ $store.state.user.userName }}</strong>
      </v-list-item>
      <v-divider />
      <v-list-item>
        <v-list-item-title
          ><strong>{{ $t("jobTitle") }}: </strong></v-list-item-title
        >
        <strong>{{
          $store.state.user.user.jobTitle == null
            ? "Admin"
            : $store.state.user.user.jobTitle
        }}</strong>
      </v-list-item>

      <v-divider />
      <v-list-item>
        <v-list-item-title
          ><strong> {{ $t("smh-alalwan") }}: </strong></v-list-item-title
        >
        <themeChanger />
      </v-list-item>
      <v-divider />
      <v-list-item>
        <v-list-item-title
          ><strong> {{ $t("change-image") }}: </strong></v-list-item-title
        >
        <v-tooltip bottom="">
          <template v-slot:activator="{ on }">
            <v-btn
              @click="openChangeImageDialog($store.state.user)"
              v-on="on"
              icon
            >
              <v-icon color=""> edit </v-icon>
            </v-btn>
          </template>
          <span>
            {{ $t("change-image") }}
          </span>
        </v-tooltip>
      </v-list-item>

      <v-divider />
      <v-list-item>
        <v-list-item-title
          ><strong> {{ $t("tghyyr-klmh-almrwr") }}: </strong></v-list-item-title
        >
        <v-tooltip bottom="">
          <template v-slot:activator="{ on }">
            <v-btn
              @click="openChangePasswordDialog($store.state.user)"
              v-on="on"
              icon
            >
              <v-icon color=""> lock_reset </v-icon>
            </v-btn>
          </template>
          <span>
            {{ $t("tghyyr-klmh-almrwr") }}
          </span>
        </v-tooltip>
      </v-list-item>

      <v-divider />

      <v-list-item @click="logout()">
        <v-list-item-title class="red--text"
          ><strong>
            {{ $t("tsjyl-khrwj") }}
          </strong></v-list-item-title
        >
        <v-list-item-icon>
          <v-icon class="red--text">power_settings_new</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <ChangePasswordDialog />
    <ChangeImageDialog />
  </div>
</template>

<script>
export default {
  components: {
    themeChanger: () => import("@/components/layout/themeChanger"),
    ChangePasswordDialog: () =>
      import("../../views/users/shared/changePasswordDialog"),
    ChangeImageDialog: () =>
      import("../../components/layout/changeImageDialog"),
  },

  methods: {
    logout() {
      sessionStorage.removeItem("token");
      this.$router.push("/login");
    },

    openChangePasswordDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setChangePasswordDialog");
      this.$eventBus.$emit("fill-fields");
    },
    openChangeImageDialog(item) {
      console.log(item, "this is itemsss", this.$store.state.changeImageDialog);
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setChangeImageDialog");
      this.$eventBus.$emit("fill-fields");
    },
  },

  created() {
    console.log(this.$store.state.user.user);
  },

  // created() {
  //     this.$eventBus.$on('user', (data) => {
  //         this.user = data.user
  //         // console.log(data.user.name)
  //     });
  //     this.user = JSON.parse(sessionStorage.getItem('user'))
  //     // console.log(this.user)
  // },
};
</script>
